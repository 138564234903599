import React from "react"

import { Container } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout pageInfo={{ pageName: "contact" }} homeBool="false">
      <SEO
        title="Contact"
        keywords={[
          `Cleaning`,
          `Crewe`,
          `Lettings Cleaning`,
          `professional cleaning`,
          `MM Lettings Cleaning`,
          `HMO Cleaning`,
          `Short Stay Cleaning`,
          `End of tenancy`,
          `cleaning service`,
          `Nantwich`,
          `Cheshire East`,
        ]}
      />
      <Container>
        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div>
              <iframe
                style={{ border: 0, width: "100%", height: "270px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d616036.7224074921!2d-2.4804425!3d52.9053443!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x906d7c8d869ea13!2sMM%20Cleaning%20Services%20(UK)%20Ltd!5e0!3m2!1sen!2suk!4v1622917416387!5m2!1sen!2suk"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>mmlettingscleaningltd@gmail.com</p>
                  </div>
                  <div className="email">
                    <h4>Address:</h4>
                    <p>42 Bramhall Rd, Crewe CW2 8PT</p>
                  </div>
                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Telelphone:</h4>
                    <p>01270749380</p>
                    <h4>Mobile:</h4>
                    <p>07724496352</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form
                  name="contact"
                  method="post"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  className="php-email-form"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default contact
